<template>
    <div>
        <div class="row" v-if="product.product">
            <div class="col-sm-6 col-lg-6 post_view">

                <div class="accordion accordion-flush" id="accordionFlushExample2">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-getpost" aria-expanded="false" aria-controls="flush-getpost">
                                Post view
                            </button>
                        </h2>
                        <div id="flush-getpost" class="accordion-collapse collapse show"
                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample2">
                            <div class="accordion-body">
                                {{ product.product.status }}<br>
                                {{ product.product.title }}<br>
                                {{ product.product.slug }}<br>
                                <b>Price: </b>
                                <span v-for="postmoney in product.product.money" :key="postmoney.id">{{ $postmoney.country.type
                                    }} {{ $postmoney.price }}</span>
                                <br>
                                <div v-html="product.product.content_full"></div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="col-sm-6 col-lg-6 post_view">
                <div class="accordion accordion-flush box_body" id="accordionFlushExample">
                    <!-- CONTENT SMALL -->
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-postprivate" aria-expanded="false"
                                aria-controls="flush-collapseOne">
                                Post private
                            </button>
                        </h2>
                        <div id="flush-postprivate" class="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <form class="col-md-12" @submit.prevent="formContentSmall('formContentSmall')" ref="formContentSmall">
                                    <input type="hidden" name="product_id" :value="product.product.id">
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">content_small</label>
                                        <textarea type="text" ref="content_small" id="content_small" class="form-control input-lg"
                                            placeholder="Text Here" name="content_small" rows="10"
                                            v-model="product.product.content_small"></textarea>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Save</button>
                                    <button type="button" class="btn btn-primary"
                                        v-on:click="btn_copy('content_small')">Copy</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- PRODUCT -->
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne" aria-expanded="false"
                                aria-controls="flush-collapseOne">
                                Product
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <form class="col-md-12" @submit.prevent="formContentSmall('formProduct')" ref="formProduct">
                                    <input type="hidden" name="product_id" :value="product.product.id">

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Title</label><br>
                                        <input type="text" class="form-control" id="exampleInputEmail1" name="title"
                                            v-model="product.product.title">
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Title small</label><br>
                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                            name="title_small" v-model="product.product.title_small">
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Slug - 225</label><br>
                                        <textarea type="text" class="form-control input-lg" placeholder="Text Here"
                                            name="slug" rows="5" v-model="product.product.slug"></textarea>
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">metaDescription</label>

                                        <textarea type="text" class="form-control input-lg" placeholder="Text Here"
                                            name="metaDescription" v-model="product.product.metaDescription"></textarea>

                                        <div id="emailHelp" class="form-text">We'll never share your email with anyone
                                            else.</div>
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">code_product</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" name="code_product"
                                            v-model="product.product.number_post">
                                        <div id="emailHelp" class="form-text">We'll never share your email with anyone
                                            else.</div>
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">content_full</label>
                                        <textarea type="text" class="form-control input-lg" placeholder="Text Here"
                                            name="content_full" rows="20" v-model="product.product.content_full"></textarea>
                                    </div>

                                    <div class="mb-3" hidden>
                                        <label for="exampleInputEmail1" class="form-label">colors</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" name="colors" v-model="product.product.colors">
                                        <div id="emailHelp" class="form-text">We'll never share your email with anyone
                                            else.</div>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="status"
                                            id="flexRadioDefault1" value="DRAFT" v-model="product.product.status" />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            DRAFT
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="status"
                                            id="flexRadioDefault2" value="PUBLISHED" v-model="product.product.status" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            PUBLISHED
                                        </label>
                                    </div>
                                    <button type="submit" class="btn btn-primary bnt_submit">Save Product</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- IMAGES -->
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                aria-controls="flush-collapseTwo">
                                Images
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <div v-if="product.product.images">
                                    <form class="col-md-12" @submit.prevent="submitFormImageUpdate('formImageMeta')" ref="formImageMeta">
                                        <label for="exampleInputEmail1" class="form-label">Images</label><br>
                                        <div v-for="(item, key) in product.product.images" :key="key">
                                            <img :src="item.path" alt="" style="width:auto;height:100px;">
                                            <p>{{ item.meta }}</p>
                                            <input class="form-check-input" type="radio" name="statusMeta"
                                                :id="'radioImage' + key"
                                                :checked="item.meta == 1" @click="submitFormImageUpdate('formImageMeta',item.id)">
                                            <button type="button" class="btn btn-primary" 
                                            v-on:click="itemImageMetadelete('formImageMeta', item.id);">X</button>
                                        </div>

                                        <button type="submit" class="btn btn-primary"
                                            hidden>Submit</button>
                                    </form>
                                </div>

                                <br><br>

                                <form class="col-md-12" @submit.prevent="submitFormImage('formUpdateImageUrl')" ref="formUpdateImageUrl">
                                    <div class="form-group row">
                                        <label for="exampleInputEmail1" class="form-label">Update url images jpg (,)</label>
                                        <div class="col-sm-8">
                                            <textarea type="text" class="form-control input-lg" placeholder="Text Here"
                                                name="image_url"></textarea>
                                        </div>
                                        <div class="col-sm-2">
                                            <button type="submit" class="btn btn-primary bnt_submit">Update</button>
                                        </div>
                                    </div>
                                </form>

                                <form class="col-md-12" @submit.prevent="submitForm('formUpdateImageFile')" ref="formUpdateImageFile" hidden>
                                    <input type="hidden" name="product_id" :value="product.product.id">

                                    <div class="input-group">
                                        <input type="file" class="form-control" id="inputGroupFile04" name="file_image"
                                            aria-describedby="inputGroupFileAddon04" aria-label="Upload">
                                        <button class="btn btn-outline-secondary" type="button"
                                            id="inputGroupFileAddon04" hidden
                                            v-on:click="update_img_file('{{product.product.slug}}', '{{product.product.id}}')">Submit</button>
                                        
                                        <button type="submit" class="btn btn-primary bnt_submit" id="bnt_submit">Submit</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- MONEY -->
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-money" aria-expanded="false" aria-controls="flush-money">
                                Money
                            </button>
                        </h2>
                        <div id="flush-money" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <div v-for="(item, key) in product.product.moneys" :key="item.id">
                                    <form @submit.prevent="submitFormPriceUpdate(item.id,product.product.id,'formPriceUpdate'+item.id)" :ref="'formPriceUpdate'+item.id">
                                        <input type="hidden" name="money_id" :value="item.id">
                                        <div class="row g-3 align-items-end">
                                            <div class="col-sm-5">
                                                <label for="exampleInputEmail1" class="form-label">Price:
                                                    <b>{{ key }}</b></label>
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" name="price_normal"
                                                    v-model="item.price">
                                            </div>
                                            <div class="col-sm-3">
                                                <label for="exampleFormControlSelect1">Money</label>
                                                <select class="form-control" id="exampleFormControlSelect1"
                                                    name="country_id">
                                                    <option v-for="(country, index) in product.countries" :key="index"
                                                        :value="country.id">
                                                        {{ country.type }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-3">
                                                <button type="button" class="btn btn-primary btn_image_submit"
                                                    v-on:click="priceDetelete(item.id,product.product.id);">X</button>
                                                <button type="submit" class="btn btn-primary" >V</button>

                                            </div>
                                        </div>
                                        <br>
                                    </form>
                                </div>

                                <form class="row g-3 align-items-end" @submit.prevent="addPriceProduct('formAddPrice')" ref="formAddPrice">
                                    <div class="col-sm-6">
                                        <label for="exampleInputEmail1" class="form-label">Add price</label>
                                        <input type="text" class="form-control" name="addprice" v-model="addPrice">
                                    </div>
                                    <div class="col-sm-3">
                                        <label for="exampleFormControlSelect1" class="form-label">Money</label>
                                        <select class="form-control" id="exampleFormControlSelect1" name="country_id">
                                            <option :value="item.id" v-for="item in product.countries" :key="item.id">{{ item.type }}</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-2">
                                        <button type="submit" class="btn btn-primary" >Add new</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- CATEGORY -->
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-categories" aria-expanded="false"
                                aria-controls="flush-categories">
                                Categories
                            </button>
                        </h2>
                        <div id="flush-categories" class="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <div class="row" v-for="category in product.product.categories" :key="category.id">
                                    <form @submit.prevent="categoryRelated('formCategoryDelete'+category.id)" :ref="'formCategoryDelete'+category.id">
                                        <input type="hidden" name="category_id" :value="category.id">
                                        <div class="col-3">
                                            <img style="width:auto;height:100px;" :src="category.path" alt="">
                                        </div>
                                        <div class="col">
                                            {{ category.name }} <br>
                                        </div>
                                        <div class="col-2">
                                            <button type="submit" class="btn btn-primary" >X</button>
                                        </div>
                                    </form>
                                </div>
                                <br>

                                <form  @submit.prevent="categoryStore('formAddCategory')" ref="formAddCategory">
                                    <div class="row align-items-end">
                                        <div class="col-8">
                                            <label for="dataListCategory" class="form-label">Add product category</label>
                                            <ColorProductsShopsInput
                                                inputId="dataListCategory"
                                                listId="dataListCategoryNew"
                                                :items="product.categories"
                                                placeholder="Type to search..."
                                                name="category"
                                                v-model="selectedCategory"
                                            />
                                        </div>
                                        <div class="col-2">
                                            <button type="submit" class="btn btn-primary" >Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- COLORS -->
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-colors" aria-expanded="false" aria-controls="flush-colors">
                                Colors
                            </button>
                        </h2>
                        <div id="flush-colors" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">

                                <div class="row" v-for="color in product.product.product_colors" :key="color.id">
                                    <form @submit.prevent="colorRelated('formColorDelete'+color.id)" :ref="'formColorDelete'+color.id">
                                        <input type="hidden" name="color_id" :value="color.id">
                                        <div class="col">
                                            {{ color.id }} |{{ color.name }} | {{ color.status }} |
                                            {{ color.available }} <br>
                                        </div>
                                        <div class="col-2">
                                            <button type="submit" class="btn btn-primary" >Xx</button>
                                        </div>
                                    </form>
                                </div>
                                <br>

                                <div class="row align-items-end">
                                    <div class="col-8">
                                        <label for="dataListDepartaments5" class="form-label">Add product color</label>
                                        <ColorProductsShopsInput
                                            inputId="dataListDepartaments5"
                                            listId="dataListDepartamentsNew5"
                                            :items="product.colors"
                                            placeholder="Type to search..."
                                            name="colors"
                                            v-model="selectedColor"
                                        />
                                    </div>
                                    <div class="col-2">
                                        <button type="submit" class="btn btn-primary" @click="colorAdd" >Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- RELATED -->
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-ralated" aria-expanded="false" aria-controls="flush-ralated">
                                Related
                            </button>
                        </h2>
                        <div id="flush-ralated" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <h2>Related</h2>

                                <form id="add_related_delete">
                                    <div class="row" v-for="item in product.product.relatives" :key="item.id">
                                        <div class="col-2">
                                            <div v-for="(image, index) in item.images" :key="index">
                                                <img v-if="index == 0" style="width:auto;height:100px;"
                                                    :src="image.thumbnail" alt="">
                                            </div>
                                        </div>
                                        <div class="col">
                                            {{ item.title }} <br>
                                        </div>
                                        <div class="col-2">
                                            <button type="button" class="btn btn-primary btn_image_submit"
                                                v-on:click="productRelated(item.id);">X</button>
                                        </div>
                                    </div>
                                    <br>
                                </form>

                                <div class="row align-items-end">
                                    <!-- Products -->
                                    <div class="col-8">
                                        <label for="datalistOptionsName2" class="form-label">Add product related</label>
                                        <InputProduct
                                            inputId="inputProduct2"
                                            listId="datalistOptionsName2"
                                            placeholder="Enter your product"
                                            name="ProductRelated"
                                            :items="filteredProducts"
                                            v-model="selectedRelated"
                                        />
                                    </div>
                                    <div class="col-2">
                                        <button type="button" class="btn btn-primary"
                                                v-on:click="addProductRelated();">Submit
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <!-- REVIEWS -->
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-reviews" aria-expanded="false" aria-controls="flush-reviews">
                                Reviews
                            </button>
                        </h2>
                        <div id="flush-reviews" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <h2>Reviews</h2>

                                <form class="row g-3 align-items-end" @submit.prevent="submitFormReview('formReviewImageList')" ref="formReviewImageList">
                                    <div class="mb-3">
                                        <label for="exampleFormControlTextarea1" class="form-label">Images list (,)</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                            name="list" v-model="reviewsImages"></textarea>
                                    </div>

                                    <div class="col-sm-2">
                                        <button type="submit" class="btn btn-primary">Add</button>
                                    </div>
                                </form>
                                <hr>
                                <form class="row g-3 align-items-end" @submit.prevent="submitFormReview('formReviewAdd')" ref="formReviewAdd">
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Rating</label><br>
                                        <input type="text" class="form-control" id="exampleInputEmail1" name="rating"
                                            value="">
                                    </div>
                                    <div class="row">
                                        <label for="exampleFormControlInput1" class="form-label">Name</label>
                                        <div class="col">
                                            <input type="text" class="form-control" id="exampleFormControlInput1"
                                                placeholder="name" name="name" v-model="nombreCompleto">
                                        </div>
                                        <div class="col-3">
                                            <button type="button" class="btn btn-primary"
                                                v-on:click="generateName()">Generate</button>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleFormControlTextarea1" class="form-label">Body</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                            name="body"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Image url</label>
                                        <input type="text" class="form-control" id="exampleFormControlInput1"
                                            placeholder="name" name="images">
                                    </div>

                                    <div class="col-sm-2">
                                        <button type="submit" class="btn btn-primary">Add</button>
                                    </div>
                                </form>

                                <hr>

                                <div class="row" v-for="item in product.product.reviews" :key="item.id">
                                    <div class="col-3">
                                        <img style="width:auto;height:100px;" :src="item.images" alt="">
                                    </div>
                                    <div class="col-6">
                                        {{ item.status }} - {{ item.rating }} - {{ item.name }}<br>
                                        {{ item.body }} <br>
                                    </div>
                                    <div class="col-3">
                                        <div>
                                            <button class="btn btn-primary" @click="reviewUpdatePublished(item.id)">Public</button>
                                        </div>
                                        <button @click="openModalProductReview(item.id)" class="btn btn-primary">Edit</button>
                                        <form  @submit.prevent="submitFormReviewDelete('formReviewDelete'+item.id)" :ref="'formReviewDelete'+item.id">
                                            <input type="hidden" name="review_id" :value="item.id">
                                            <button type="submit" class="btn btn-danger">Delete</button>
                                        </form>
                                    </div>

                                    <br><br><hr>

                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- PACKS -->
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-packs" aria-expanded="false" aria-controls="flush-packs">
                                Packs
                            </button>
                        </h2>
                        <div id="flush-packs" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <div v-for="(pack, index) in product.product.packs" :key="index">
                                    <div v-if="pack">
                                        <div class="row">
                                            
                                            <div class="col-3">
                                                <img style="width:auto;height:100px;" v-if="pack.related_product.image_only && pack.related_product.image_only.thumbnail"
                                                    :src="pack.related_product.image_only.thumbnail" alt="">
                                            </div>
                                            <div class="col">
                                                <p>
                                                    <a :href="pack.related_product.id" target="_blank" rel="noopener noreferrer">
                                                        {{ pack.related_product['title'] }}
                                                    </a> <br>
                                                    <b>Status: </b>{{  pack.related_product['status'] }} <br>
                                                    <b>Color: </b>{{ pack.related_product['colors'] }} <br>
                                                    <b>Price: </b>{{ pack.related_product['moneys'][1]['price'] }}<br>

                                                </p>
                                                <p>

                                                    <b>Status: </b>{{ pack.status }} <br>
                                                    <b>Precio: </b> S/. {{ pack.price }}<br>
                                                    <span v-for="price in pack.related_product.money" :key="price.id">{{
                                                        $price.price }} <br></span>
                                                </p>
                                            </div>
                                            <div class="col-2">
                                                <form @submit.prevent="submitFormPackRelated('formPackRelated'+pack.id)" :ref="'formPackRelated'+pack.id">
                                                    <input type="hidden" name="pack_id" :value="pack.id">
                                                    <button type="submit" class="btn btn-danger">X</button>
                                                </form>
                                            </div>
                                        </div>
                                        <form @submit.prevent="submitFormPackUpdate('formPackUpdate'+pack.id)" :ref="'formPackUpdate'+pack.id">
                                            <input type="hidden" name="pack_id" :value="pack.id">
                                            
                                            <div class="row">
                                                <div class="col-2">
                                                </div>
                                                <div class="col-8">
                                                    <div class="row">
                                                        <div class="col-2">
                                                            <label for="exampleInputEmail1"
                                                                class="form-label">Price</label><br>
                                                        </div>
                                                        <div class="col-10">
                                                            <input type="text" class="form-control"
                                                                id="exampleInputEmail1" name="price"
                                                                v-model="pack.price">
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-2">
                                                            <label for="exampleFormControlInput1"
                                                                class="form-label">Status</label>
                                                        </div>
                                                        <div class="col-10">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="status" id="flexRadioDefault1" value="DRAFT"
                                                                    v-model="pack.status">
                                                                <label class="form-check-label" for="flexRadioDefault1">
                                                                    DRAFT
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    name="status" id="flexRadioDefault2"
                                                                    value="PUBLISHED" v-model="pack.status">
                                                                <label class="form-check-label" for="flexRadioDefault2">
                                                                    PUBLISHED
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <button type="submit" class="btn btn-primary">V</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div v-else>{{ pack }}</div>
                                    <hr>
                                </div>
                                <form>
                                    <div class="row align-items-end">
                                        <!-- Products -->
                                        <div class="col-8">
                                            <label for="datalistOptionsProductPack" class="form-label">Add product pack</label>
                                            <InputProduct
                                                inputId="inputProductPack"
                                                listId="datalistOptionsProductPack"
                                                placeholder="Enter your product"
                                                name="ProductPack"
                                                :items="filteredProducts"
                                                v-model="selectedPack"
                                            />
                                        </div>
                                        <div class="col-2">
                                            <button type="button" class="btn btn-primary"
                                                v-on:click="addProductPack();">Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Button trigger modal - PRODUCT NO RELATED  -->
        <a type="button" id="btnModalProductReview" hidden class="btn btn-primary" data-bs-toggle="modal"
            data-bs-target="#ModalProductReview">
            Launch demo modal
        </a>

        <!-- Modal - PRODUCT REVIEW -->
        <div class="modal fade" id="ModalProductReview" tabindex="-1" aria-labelledby="orderModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="orderModalLabel">Products Review</h5>
                <a type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></a>
            </div>
            <div class="modal-body">

                <div class="col-md-12">

                    <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">rating</label><br>
                        <input type="text" class="form-control" id="exampleInputEmail1" name="rating" v-model="reviewSelect.rating">
                    </div>

                    <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">name</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="name" v-model="reviewSelect.name">
                        <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                    </div>

                    <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">body</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="body" v-model="reviewSelect.body">
                    </div>
                    
                    <div class="row">
                        <div class="col-6">
                            <img style="width:auto;height:100px;" :src="reviewSelect.images" alt="">
                        </div>
                        <div class="mb-6">
                            <label for="exampleInputEmail1" class="form-label">Image</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="images" v-model="reviewSelect.images">
                        </div>
                    </div>

                    <label for="exampleInputEmail1" class="form-label">status</label>
                    <div class="form-check">
                        <input class="form-check-input" 
                            type="radio" 
                            name="status" 
                            id="flexRadioDefault1" 
                            value="DRAFT" 
                            v-model="reviewSelect.status"
                            :checked="reviewSelect.status == 'DRAFT'">
                        <label class="form-check-label" for="flexRadioDefault1">
                            DRAF
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" 
                            type="radio" 
                            name="status" 
                            id="flexRadioDefault2" 
                            value="PUBLISHED"  
                            v-model="reviewSelect.status"
                            :checked="reviewSelect.status == 'PUBLISHED'">
                        <label class="form-check-label" for="flexRadioDefault2">
                            PUBLISHED
                        </label>
                    </div>

                </div>
                
            </div>
            <div class="modal-footer">
                <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</a>
                <a type="button" class="btn btn-primary" @click="reviewUpdate()">Save</a>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

import ColorProductsShopsInput from '@/components/input/ColorProductsShopsInput.vue';
import InputProduct from '@/components/input/InputProduct.vue';

import PeruName from '@/json/peru/names.json';
import PeruLastName from '@/json/peru/lastsnames.json';


export default {
    name:'ProductEdit',
    components: {
        ColorProductsShopsInput,
        InputProduct,
    },
    created() {
        this.$store.dispatch("shopsProducts/getProducts");
    },
    mounted() {
        this.getProduct();
    },
    data() {
        return {
            product_id: this.$route.params.id,
            product: [],
            selectedImage:null,
            selectedColor: null,
            selectedCategory: null,
            selectedPack: {
                title:null,
                title_small:null,
            },
            addPrice:'',
            selectedRelated:{
                title:null,
                title_small:null,
            },
            rating:[
                4,5
            ],
            peru: {
                names: PeruName.names,
                lastsnames: PeruLastName.lastsnames,
            },
            nombreCompleto: "",
            reviewsImages:"",
            reviewSelect:[]
        }
    },
    methods: {
        getProduct() {
            const url = this.appDomainApi + "shops/product/" + this.product_id;
            axios.get(url,)
                .then(res => {
                    console.log(res.data)
                    this.product = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        formContentSmall(refFormName) {
            console.log("formContentSmall refFormName:"+refFormName)
            // const form = this.$refs.formProduct;
            const form = this.$refs[refFormName];
            const formData = new FormData(form);

            // Añadir el nombre del formulario al FormData
            formData.append('formName', refFormName);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            const url = this.appDomainApi + "shops/product/save-product/" + this.product_id;

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        submitFormImage(refFormName) {
            console.log("submitFormImage refFormName:"+refFormName)
            // const form = this.$refs.formProduct;
            const form = this.$refs[refFormName];
            const formData = new FormData(form);

            formData.append('product_id', this.product_id);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            const url = this.appDomainApi + "shops/product/image/store/" + this.product_id;

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                    this.product.product.images = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        submitFormImageUpdate(refFormName,image_id) {
            console.log("submitFormImageUpdate refFormName:"+refFormName)
            // const form = this.$refs.formProduct;
            const form = this.$refs[refFormName];
            const formData = new FormData(form);

            formData.append('product_id', this.product_id);
            formData.append('image_id', image_id);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            const url = this.appDomainApi + "shops/product/image/update/" + this.product_id;

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                    this.product.product.images = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        itemImageMetadelete(refFormName, image_id) {
            console.log("itemImageMetadelete refFormName: "+refFormName +" image_id: "+image_id)

            const url = this.appDomainApi + "shops/product/image/related/" + this.product_id;

            const data = {
                'id':image_id,
            }

            axios.post(url, data)
                .then(response => {
                    console.log(response.data);
                    this.product.product.images = [];
                    this.product.product.images = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        addPriceProduct(refFormName) {
            console.log("addPriceProduct: "+refFormName)

            const form = this.$refs[refFormName];
            const formData = new FormData(form);

            // Añadir el nombre del formulario al FormData
            formData.append('formName', refFormName);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }

            const url = this.appDomainApi + "shops/product/money/store/" + this.product_id;

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                    this.product.product.moneys = response.data;
                    this.addPrice = '';
                })
                .catch(error => {
                    console.error(error);
                });
            
        },
        submitFormPriceUpdate(item_id, product_id, formPriceUpdate) {
            console.log("submitFormPriceUpdate: "+item_id +" product_id: "+product_id+" formPriceUpdate: "+formPriceUpdate)

            const form = this.$refs[formPriceUpdate][0];
            const formData = new FormData(form);

            // Añadir el nombre del formulario al FormData
            formData.append('formName', formPriceUpdate);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }

            const url = this.appDomainApi + "shops/product/money/update/" + this.product_id;

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                    this.product.product.moneys = response.data;
                    this.addPrice = '';
                })
                .catch(error => {
                    console.error(error);
                });
            
        },
        priceDetelete(item_id) {
            console.log("priceDetelete: "+item_id)

            const url = this.appDomainApi + "shops/product/money/delete/" + item_id;

            axios.delete(url)
                .then(response => {
                    console.log(response.data);
                    this.product.product.moneys = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
            
        },
        categoryStore() {
            console.log("categoryStore")
            console.log(this.selectedCategory)

            const url = this.appDomainApi + "shops/product/category/store/" + this.product_id;

            axios.post(url, this.selectedCategory)
                .then(response => {
                    console.log(response.data);
                    this.product.product.categories = response.data;
                    this.selectedCategory = '';
                })
                .catch(error => {
                    console.error(error);
                });
            
        },
        categoryRelated(formPriceUpdate) {
            console.log("formPriceUpdate: "+formPriceUpdate)
            const form = this.$refs[formPriceUpdate][0];
            const formData = new FormData(form);

            // Añadir el nombre del formulario al FormData
            formData.append('product_id', this.product_id);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }

            const url = this.appDomainApi + "shops/product/category/related/" + this.product_id;

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                    this.product.product.categories = response.data;
                    this.selectedCategory = '';
                })
                .catch(error => {
                    console.error(error);
                });
            
        },
        colorAdd() {
            console.log("selectedColor")
            console.log(this.selectedColor)

            const url = this.appDomainApi + "shops/product/color/store/" + this.product_id;

            axios.post(url, this.selectedColor)
                .then(response => {
                    console.log(response.data);
                    this.product.product.product_colors = response.data;
                    this.selectedColor = '';
                })
                .catch(error => {
                    console.error(error);
                });
            
        },
        colorRelated(refFormName) {
            console.log("colorDelete: "+refFormName)
            console.log(refFormName)

            const form = this.$refs[refFormName][0];
            const formData = new FormData(form);

            formData.append('product_id', this.product_id);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }

            const url = this.appDomainApi + "shops/product/color/related/" + this.product_id;

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                    this.product.product.product_colors = response.data;
                    this.selectedColor = '';
                })
                .catch(error => {
                    console.error(error);
                });
            
        },
        addProductRelated() {
            console.log("addProductRelated")
            console.log(this.selectedRelated)

            const url = this.appDomainApi + "shops/product/related/store/" + this.product_id;

            axios.post(url, this.selectedRelated)
                .then(response => {
                    console.log(response.data);
                    this.product.product.relatives = response.data;
                    // this.selectedColor = '';
                })
                .catch(error => {
                    console.error(error);
                });
            
        },
        productRelated(item_id) {
            console.log("productRelated")
            console.log(item_id)

            const url = this.appDomainApi + "shops/product/related/related/" + this.product_id;

            const data = {
                'id':item_id,
            }

            axios.post(url, data)
                .then(response => {
                    console.log(response.data);
                    this.product.product.relatives = response.data;
                    // this.selectedColor = '';
                })
                .catch(error => {
                    console.error(error);
                });
            
        },
        
        formImageMeta(refFormName) {
            console.log("formImageMeta refFormName:"+refFormName)
            // const form = this.$refs.formProduct;
            const form = this.$refs[refFormName];
            const formData = new FormData(form);

            // Añadir el nombre del formulario al FormData
            formData.append('formName', refFormName);
            formData.append('formNamesss', this.selectedRelated);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            const url = "/shopnew/posts/save/<?php echo $posts->id ?>"

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        
        submitForm(refFormName) {
            console.log("submitForm refFormName:"+refFormName)
            // const form = this.$refs.formProduct;
            const form = this.$refs[refFormName];
            const formData = new FormData(form);

            // Añadir el nombre del formulario al FormData
            formData.append('formName', refFormName);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            const url = "/shopnew/posts/save/<?php echo $posts->id ?>"

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        btn_copy(refName) {
            console.log("btn_copy")
            console.log(refName)
            const textElement = this.$refs[refName];

            if (textElement) {
                // Seleccionar el texto
                textElement.select();
                try {
                // Intentar copiar el texto al portapapeles
                const successful = document.execCommand('copy');
                const msg = successful ? 'successful' : 'unsuccessful';
                console.log('Copy command was ' + msg);
                } catch (err) {
                console.log('Oops, unable to copy', err);
                }
            } else {
                console.log('No element found for ref:', refName);
            }
        },
        generateName() {
            // Obtener un nombre y un apellido aleatorio
            const nombreAleatorio = this.peru.names[Math.floor(Math.random() * this.peru.names.length)];
            const apellidoAleatorio = this.peru.lastsnames[Math.floor(Math.random() * this.peru.lastsnames.length)];
            
            // Combinar el nombre y el apellido
            // this.nombreCompleto = `${nombreAleatorio} ${apellidoAleatorio}`;
            this.nombreCompleto = `${this.capitalize(nombreAleatorio)} ${this.capitalize(apellidoAleatorio)}`;

            console.log(this.nombreCompleto)
        },
        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        },
        submitFormReview(refFormName) {
            console.log("submitFormReview: "+refFormName)
            // const form = this.$refs.formProduct;
            var form = '';
            if (refFormName == "formReviewAdd" || refFormName == "formReviewImageList") {
                console.log("if")
                form = this.$refs[refFormName];
            } else {
                console.log("else")
                form = this.$refs[refFormName][0];
            }
            const formData = new FormData(form);

            // Añadir el nombre del formulario al FormData
            formData.append('formName', refFormName);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            const url = this.appDomainApi + "shops/product/review/store/" + this.product_id;

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                    this.product.product.reviews = response.data;

                })
                .catch(error => {
                    console.error(error);
                });
        },
        submitFormReviewUpdate(refFormName) {
            console.log("submitFormReviewUpdate: "+refFormName)
            // const form = this.$refs.formProduct;
            var form = '';
            if (refFormName == "formReviewAdd") {
                console.log("if")
                form = this.$refs[refFormName];
            } else {
                console.log("else")
                form = this.$refs[refFormName][0];
            }
            const formData = new FormData(form);

            // Añadir el nombre del formulario al FormData
            formData.append('formName', refFormName);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            const url = this.appDomainApi + "shops/product/review/update/" + this.product_id;

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                    this.product.product.reviews = response.data;

                })
                .catch(error => {
                    console.error(error);
                });
        },
        submitFormReviewDelete(refFormName) {
            console.log("submitFormReviewDelete: "+refFormName)
            // const form = this.$refs.formProduct;
            var form = '';
            if (refFormName == "formReviewAdd") {
                console.log("if")
                form = this.$refs[refFormName];
            } else {
                console.log("else")
                form = this.$refs[refFormName][0];
            }
            const formData = new FormData(form);

            // Añadir el nombre del formulario al FormData
            formData.append('formName', refFormName);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            const url = this.appDomainApi + "shops/product/review/related/" + this.product_id;

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                    this.product.product.reviews = response.data;

                })
                .catch(error => {
                    console.error(error);
                });
        },
        addProductPack() {
            console.log("addProductPack")
            console.log(this.selectedPack)

            const url = this.appDomainApi + "shops/product/pack/store/" + this.product_id;

            axios.post(url, this.selectedPack)
                .then(response => {
                    console.log(response.data);
                    this.product.product.packs = response.data;
                    // this.selectedColor = '';
                })
                .catch(error => {
                    console.error(error);
                });
            
        },
        submitFormPackUpdate(refFormName) {
            console.log("submitFormPackUpdate: "+refFormName)
            // const form = this.$refs.formProduct;
            var form = this.$refs[refFormName][0];
            const formData = new FormData(form);

            // Añadir el nombre del formulario al FormData
            formData.append('formName', refFormName);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            const url = this.appDomainApi + "shops/product/pack/update/" + this.product_id;

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                    this.product.product.packs = response.data;

                })
                .catch(error => {
                    console.error(error);
                });
        },
        submitFormPackRelated(refFormName) {
            console.log("submitFormPackRelated: "+refFormName)
            // const form = this.$refs.formProduct;
            var form = this.$refs[refFormName][0];
            const formData = new FormData(form);

            // Añadir el nombre del formulario al FormData
            formData.append('formName', refFormName);

            // Imprimir los datos del formulario en la consola
            for (const [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            const url = this.appDomainApi + "shops/product/pack/related/" + this.product_id;

            axios.post(url, formData)
                .then(response => {
                    console.log(response.data);
                    this.product.product.packs = response.data;

                })
                .catch(error => {
                    console.error(error);
                });
        },
        openModalProductReview(review_id) {
            // this.getProductsNoRelated();
            // const item = this.shopsProducts.products[shopname].find(item => item.id === this.orderCreate.product.id);
            //         return item.colors;

            const item = this.product.product.reviews.find(item => item.id == review_id);

            this.reviewSelect = item;
            console.log(item)

            document.getElementById('btnModalProductReview').click();
        },
        reviewUpdate(){
            console.log("reviewUpdate")

            const url = this.appDomainApi + "shops/product/review/update/" + this.product_id;

            axios.post(url, this.reviewSelect)
                .then(response => {
                    console.log(response.data);
                    this.product.product.review = response.data;

                })
                .catch(error => {
                    console.error(error);
                });
        },
        reviewUpdatePublished(review_id){
            console.log("reviewUpdatePublished")

            const url = this.appDomainApi + "shops/product/review/update/" + this.product_id;

            const data = {
                id: review_id,
                status: 'PUBLISHED'
            }

            axios.post(url, data)
                .then(response => {
                    console.log(response.data);
                    this.product.product.review = response.data;

                })
                .catch(error => {
                    console.error(error);
                });
        },
    },
    computed: {
        ...mapState([
            "shopsProducts",
        ]),
        filteredProducts() {
                console.log("filteredProducts");
                if (this.shopsProducts) {
                    // var shopname = this.orderCreate.shop.toLowerCase();
                    var shopname = this.product.product.shop_stores[0].name;
                    const item = this.shopsProducts.products[shopname] || [];
                    return item;
                } else {
                    return []
                }
            },
    },
}
</script>

<style scoped>
.post_view {
        /* width: 50%; */
        height: 100vh;
        overflow: scroll;
    }
</style>